.App {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, teal, navy);
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
} 